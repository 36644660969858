.site-layout-background {
}
:where(.css-dev-only-do-not-override-i3gose).ant-layout .ant-layout-header {
  background-color: #f5a60b !important;
}

/* :where(.css-dev-only-do-not-override-i3gose).ant-menu-dark {
  background-color: #010101 !important;
} */
/* .ant-layout-sider-children {
  background-color: #080808 !important;
  overflow-y: scroll;
} */

 .ant-layout-sider-children {
  background-color: #080808 !important;
  overflow-y: hidden;  
}

#example1 {
  box-shadow: 5px 10px;
}

#example2 {
  box-shadow: 5px 10px #888888;
}

.red-text {
  color: red;
}
.ant-layout-sider-children:hover {
  overflow-y: scroll; /* Display overflow when hovered */
}

/* .ant-layout-sider {
  width: 20px !important;
  overflow-y: hidden;  
} 

/* aside {
  height: 50vw;
} */
.orangeFactory {
  background-color: #f5a60b !important;
  border-color: #f5a60b !important;
}

.custom-nil-text {
  color: red; /* Set your desired text color */
  /* Add other styles as needed */
}

.custom-five-digits {
  background-color: rgb(
    191,
    246,
    191
  ); /* Background color for rows with 5-digit customerId */
}

.custom-background {
  opacity: 0;
  transition: opacity 1s ease; /* Adjust the duration and timing function as needed. */
  width: 130px;
}

.custom-background-enter {
  opacity: 0;
  transition: opacity 1s ease; /* Adjust the duration and timing function as needed. */
}

.custom-background-enter.show {
  opacity: 2;
}

.custom-background.show {
  opacity: 2;
}


body,
html {
  font-family: 'Manrope', sans-serif;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* .ant-menu-submenu-title {
  color: white !important;
  height: 85px !important;
  font-size: 19px !important;
  font-weight: 500 !important;
  text-align: center !important;
  font-family: sans-serif Poppins !important;
}

.ant-menu-submenu-active .ant-menu-submenu-title,
.ant-menu-submenu-active ~ .ant-menu-submenu-title {
  background-color: white !important;
  color: #f5a60b !important;
  font-weight: 700 !important;
  border-left: 5px solid #f5a60b;
} */

.ant-menu-submenu-arrow {
  display: none !important;
}

/* .ant-menu-submenu-popup {
  box-shadow: 4px 4px 10px #f5a60b !important;
  font-size: 18px !important;
  font-family: Poppins, sans-serif !important;
  width: 300px !important;
  border-radius: 20px !important;
} */

.count {
  color: red !important;
  float: right;
}

.ant-menu-sub {
  padding-bottom: 10px !important;
}

/* .ant-menu-item-icon {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}
.ant-menu-title-content {
  margin-top: 0px !important;
  font-family: sans-serif;
} */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* .container {
  max-width: max-content !important;
} */

/* ~~~~~~~~~~~~~~~~~~~~~~~~ */
@media only screen and (max-width: 600px) {
  .container {
    max-width: max-content !important;
  }
  aside {
    height: 100%;
  }
  /* .ant-layout-sider-collapsed {
    display: none !important;
  } */
  .anticon-menu-fold {
    display: flex !important;
  }
  .ant-layout-sider-children {
    width: 120px !important;
  }
  .ant-layout-sider {
    min-width: 120px !important;
    max-width: 120px !important;
  }
  .nav-logo {
    padding: 25px !important;
  }
  .side-nav.menu {
    color: white !important;
    height: 70px !important;
    font-size: 12px !important;
    margin-left: -10px;
    font-weight: 400 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 10px !important;
  }
  .side-nav.ant-menu-item-selected {
    font-weight: 500 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }
  .top-nav.ant-menu-item-selected {
    font-weight: 500 !important;
    background-color: #f5a60b !important;
    color: #000 !important;
    /* border-left: 5px solid #f5a60b; */
  }

  .top-nav.ant-menu-item-active {
    /* font-weight: 700 !important; */
    background-color: #f5a60b !important;
    /* color: #f5a60b !important; */
    border-left: 0px solid #f5a60b;
  }

  .ant-menu-horizontal::after {
    height: 75px;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom-color: #f5a60b !important;
  }
  .ant-menu-horizontal .ant-menu-item {
    padding-inline: 5px !important;
  }

  .anticon .anticon-user {
    display: none !important;
  }

  .ant-menu-overflow {
    display: flex;
    flex-direction: row-reverse;
  }
  .ant-menu-item-active {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .user-icon {
    display: none !important;
  }

  .anticon-ellipsis {
    display: none !important;
  }

  .side-nav > .ant-menu-submenu-title {
    color: white !important;
    height: 70px !important;
    font-size: 12px !important;
    margin-left: -10px;
    font-weight: 400 !important;
    text-align: center !important;
    font-family: sans-serif !important;
    text-align: center !important;
    padding-top: 15px !important;
  }
  .ant-menu-submenu-active .ant-menu-submenu-title,
  .ant-menu-submenu-active ~ .ant-menu-submenu-title {
    background-color: white !important;
    color: #f5a60b !important;
    font-weight: 700 !important;
    border-left: 5px solid #f5a60b;
  }
  .menu-icon {
    height: 25px !important;
    width: 25px !important;
    padding-bottom: 5px !important;
  }

  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 20px;
  }
  .ant-menu-submenu-popup {
    box-shadow: 4px 4px 10px #f5a60b !important;
    font-size: 12px !important;
    font-family: sans-serif !important;
    width: 200px !important;
    border-radius: 28px !important;
  }
  .ant-menu-vertical.ant-menu-light {
    width: 120px !important;
  }

  .ant-menu-item-icon {
    height: 15px;
    width: 15px;
  }

  .active > .ant-radio-button-checked {
    background-color: #129654 !important;
    border: 0px solid #129654 !important;
  }

  .in-active > .ant-radio-button-checked {
    background-color: #d74242 !important;
    border: 0px solid #d74242 !important;
  }

  .btn-primary {
    --bs-btn-bg: #f5a60b !important;
    --bs-btn-border-color: #f5a60b !important;

    --bs-btn-hover-bg: #f5a60b !important;
    --bs-btn-hover-border-color: #f5a60b !important;

    --bs-btn-active-bg: #f5a60b !important;
    --bs-btn-active-border-color: #f5a60b !important;

    --bs-btn-disabled-bg: #f5a60b !important;
    --bs-btn-disabled-border-color: #f5a60b !important;
  }

  .row {
    margin-left: 0px !important;
  }
  .ant-card .ant-card-body {
    padding-top: 5px;
  }
  .ant-menu-item-selected .anticon-menu-fold,
  .ant-menu-item-selected ~ .anticon-menu-fold {
    background-color: #f5a60b !important;
  }
  .ant-menu-item-selected > .anticon-menu-fold {
    background-color: #f5a60b !important;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after {
    border-bottom-color: #f5a60b !important;
  }

  .view-data > .ant-card-body {
    display: flex !important;
    flex-direction: column !important;
    padding: 10px !important;
    flex-wrap: wrap !important;
  }
  .view-data > .ant-card-body > .view-container > .view-value {
    display: flex !important;
    padding: 10px !important;
  }

  /* ~~~~~~~~~~~~~~~~~~~ */
}

/* @media only screen and (min-width: 600px) {
  aside {
    height: 100%;
  }
  .ant-layout-sider-collapsed {
    display: none !important;
  }
  .anticon-menu-fold {
    display: flex !important;
  }
  .ant-layout-sider-children {
    width: 300px !important;
  }
  .nav-logo {
    padding: 75px !important;
  }
  .menu {
    color: white !important;
    height: 170px !important;
    font-size: 37px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }
  .ant-menu-item-selected {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .ant-menu-item-active {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }
  .ant-menu-submenu-title {
    color: white !important;
    height: 170px !important;
    font-size: 37px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }
  .ant-menu-submenu-active .ant-menu-submenu-title,
  .ant-menu-submenu-active ~ .ant-menu-submenu-title {
    background-color: white !important;
    color: #f5a60b !important;
    font-weight: 700 !important;
    border-left: 5px solid #f5a60b;
  }
  .menu-icon {
    height: 60px !important;
    width: 60px !important;
    padding-bottom: 10px !important;
  }
  .ant-menu-submenu-popup {
    box-shadow: 4px 4px 10px #f5a60b !important;
    font-size: 20px !important;
    font-family: Poppins, sans-serif !important;
    width: 400px !important;
    border-radius: 20px !important;
  }
  .ant-menu-vertical.ant-menu-light {
    width: 150px !important;
  }
  .ant-menu-item {
    margin-block: 24px !important;
  }
} */

/* @media only screen and (min-width: 780px) {
  .container {
    max-width: 1800px !important;
  }

  aside {
    height: 100%;
  }
  .ant-layout-sider {
    min-width: 150px !important;
  }
  .ant-layout-sider-collapsed {
    display: none !important;
  }
  .anticon-menu-fold {
    display: flex !important;
  }
  .ant-layout-sider-children {
    width: 150px !important;
  }
  .nav-logo {
    padding: 75px !important;
  }
  .side-nav.menu {
    color: white !important;
    height: 170px !important;
    font-size: 37px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }
  .side-nav.ant-menu-item-selected {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .side-nav.ant-menu-item-active {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }
  .side-nav > .ant-menu-submenu-title {
    color: white !important;
    height: 170px !important;
    font-size: 37px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }

  .top-nav.ant-menu-item {
    color: #000 !important;
  }

  .top-nav > .ant-menu-item-active {
   
    background-color: white !important;

    border-left: 0px solid #f5a60b;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom-color: #f5a60b !important;
  }

  .top-nav.ant-menu-item-selected {
    color: #000 !important;
    background: #f5a60b !important;
  }

  .top-nav > .ant-menu-submenu-title {
    color: #f5a60b !important;
    height: 70px !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }
  .top-nav.ant-menu-item-active {
    background-color: #f5a60b !important;
    color: #000 !important;

  }

  .ant-menu-submenu-active .ant-menu-submenu-title,
  .ant-menu-submenu-active ~ .ant-menu-submenu-title {
    background-color: white !important;
    color: #f5a60b !important;
    font-weight: 700 !important;
    border-left: 5px solid #f5a60b;
  }
  .menu-icon {
    height: 60px !important;
    width: 60px !important;
    padding-bottom: 10px !important;
  }
  .ant-menu-submenu-popup {
    box-shadow: 4px 4px 10px #f5a60b !important;
    font-size: 28px !important;
    font-family: Poppins, sans-serif !important;
    width: 500px !important;
    border-radius: 20px !important;
  }
  .ant-menu-vertical.ant-menu-light {
    width: 50px !important;
  }

  .ant-menu-item {
    margin-block: 24px !important;
  }

  .ant-menu-item-icon {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px;
    height: 28px;
    width: 28px;
  }
} */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* @media only screen and (min-width: 992px) {

  .table-button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-bottom: 10px !important;
    height: 40px !important;
  }
  aside {
    height: 100%;
  }
  .ant-layout-sider-collapsed {
    display: flex !important;
  }
  .anticon-menu-fold {
    display: none !important;
  }
  .ant-layout-sider-children {
    width: 150px !important;
  }
  .ant-layout-sider {
    min-width: 150px !important;
  }
  .nav-logo {
    padding: 25px !important;
  }
  .menu {
    color: white !important;
    height: 75px !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 0px !important;
  }
  .ant-menu-item-selected {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .ant-menu-item-active {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }
  .ant-menu-submenu-title {
    color: white !important;
    height: 85px !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 1px !important;
  }
  .ant-menu-submenu-active .ant-menu-submenu-title,
  .ant-menu-submenu-active ~ .ant-menu-submenu-title {
    background-color: white !important;
    color: #f5a60b !important;
    font-weight: 700 !important;
    border-left: 5px solid #f5a60b;
  }
  .menu-icon {
    height: 20px !important;
    width: 20px !important;
    padding-bottom: 2px !important;
  }
  .ant-menu-submenu-popup {
    box-shadow: 4px 4px 10px #f5a60b !important;
    font-size: 18px !important;
    font-family: Poppins, sans-serif !important;
    width: 300px !important;
    border-radius: 20px !important;
  }
  .ant-menu-vertical.ant-menu-light {
    width: 150px !important;
  }
} */

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 100% !important;
  }
  .ant-layout-sider-collapsed {
    display: flex !important;
  }
  aside {
    height: 100%;
    position: relative;
  }
  .ant-layout-sider {
    min-width: 120px !important;
    max-width: 120px !important;
  }
  .anticon-menu-fold {
    display: none !important;
  }

  .ant-layout-sider-children {
    width: 120px !important;
  }

  .nav-logo {
    padding: 30px !important;
  }

  .ant-menu-horizontal::after {
    height: 65px;
  }
  .side-nav.menu {
    color: white !important;
    height: 72px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding: 0px !important;
    margin-block: 0px !important;
  }
  .side-nav.ant-menu-item-selected {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .side-nav.ant-menu-item-active {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    font-weight: 700 !important;
    background-color: white !important;
    color: #f5a60b !important;
    border-left: 5px solid #f5a60b;
  }

  .ant-menu-submenu-vertical.maintext .ant-menu-submenu-title {
    color: white !important;
    height: 68px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding: 0px !important;
    padding-bottom: 5px !important;
  }
  .side-nav > .ant-menu-submenu-title {
    color: white !important;
    height: 68px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding: 0px !important;
    padding-bottom: 5px !important;
  }
  .mstable div[aria-label="action"] {
    display: none;
  }

  .ant-menu-vertical .ant-menu-submenu-title {
    margin-block: 3px !important;
  }
  .top-nav.ant-menu-item {
    color: #000 !important;
    padding-bottom: 4px !important;
  }

  .top-nav.ant-menu-item-active,
  .top-nav.ant-menu-item-selected {
    font-weight: 700 !important;

    background: #f5a60b !important;

    color: #f5a60b !important;
    border-left: 0px solid #f5a60b !important;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom-color: #f5a60b !important;
  }

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after {
    border-bottom-color: #f5a60b !important;
  }

  .top-nav > .ant-menu-submenu-title {
    color: #f5a60b !important;
    height: 10px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: center !important;
    font-family: sans-serif Poppins !important;
    padding-top: 40px !important;
  }

  .side-nav.ant-menu-submenu-active .ant-menu-submenu-title,
  .side-nav.ant-menu-submenu-active ~ .ant-menu-submenu-title {
    background-color: white !important;
    color: #f5a60b !important;
    font-weight: 700 !important;
    border-left: 5px solid #f5a60b;
  }

  .menu-icon {
    height: 17px !important;
    width: 17px !important;
  }
  .ant-menu-submenu-popup {
    box-shadow: 4px 4px 10px #f5a60b !important;
    font-size: 18px !important;
    font-family: Poppins, sans-serif !important;
    width: 400px !important;
    border-radius: 20px !important;
  }

  .ant-menu-vertical.ant-menu-light {
    width: 120px !important;
  }

  .ant-menu-item {
    margin-block: 2px !important;
  }

  .ant-menu-item-icon {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }

  .btn-primary {
    --bs-btn-bg: #f5a60b !important;
    --bs-btn-border-color: #f5a60b !important;

    --bs-btn-hover-bg: #f5a60b !important;
    --bs-btn-hover-border-color: #f5a60b !important;

    --bs-btn-active-bg: #f5a60b !important;
    --bs-btn-active-border-color: #f5a60b !important;

    --bs-btn-disabled-bg: #f5a60b !important;
    --bs-btn-disabled-border-color: #f5a60b !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    text-align: center !important;
  }
  .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    color: #f5a60b !important;
    font-size: 10px;
  }
  .ant-form-item .ant-form-item-label > label {
    font-weight: 500;
  }

  .active > .ant-radio-button-checked {
    background-color: #129654 !important;
    border: 0px solid #129654 !important;
  }

  .in-active > .ant-radio-button-checked {
    background-color: #d74242 !important;
    border: 0px solid #d74242 !important;
  }

  .ant-menu-overflow {
    display: flex;
    flex-direction: row-reverse;
  }
  .ant-menu-horizontal .ant-menu-item {
    padding-inline: 5px !important;
  }
  .ant-menu-horizontal {
    gap: 10px;
  }
  .view-data > .ant-card-body {
    display: flex !important;
    flex-direction: column !important;
    padding: 10px !important;
  }
  .view-data > .ant-card-body > .view-container {
    display: flex !important;
    padding: 10px !important;
  }
  .view-data > .ant-card-body > .view-container:hover {
    background-color: rgb(244, 244, 244);
  }
  .view-data > .ant-card-body > .view-container > .view-value {
    display: flex !important;
    justify-content: space-between !important;
  }

  .category-Input {
    width: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background: #fff;
  }

  .disabled {
    pointer-events: none;
    cursor: none;
    background: rgb(71, 62, 62);
  }
  input[type='number']:disabled {
    background: #ebeaea;
    color: #bdbbbb;
  }

  .category-Input:hover {
    border: 1px solid #4096ff;
    border-radius: 6px;
  }

  .MuiTablePagination-displayedRows {
    padding-top: 18px !important;
  }
  .input-with-icon-adornment {
    width: 100px !important;
    height: 30px !important;
  }
  .MuiDataGrid-columnHeaders {
    background-color: #b19e77;
    color: white;
  }
  .ant-card .ant-card-body {
    padding-top: 4px !important;
  }
  .ant-card-head-title {
    padding: '0px !important';
  }
  .MuiTablePagination-selectLabel {
    padding-top: 13px;
  }
}

/* tab overwrite */
.ant-tabs-nav-list {
  width: 100%;
  justify-content: space-around;
}
/* .ant-tabs-tab-btn{
  color: #f5a60b !important;
} */
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f5a60b;
}
.ant-tabs-tab {
  background-color: #f5a60b !important;
  color: #fff !important;
  width: 100%;
}
.ant-tabs-tab-active {
  background-color: #fff !important;
  color: #f5a60b !important;
}

.ant-form-item-label {
  padding: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.side-nav
  > .ant-menu-submenu.ant-menu-submenu-vertical.side-nav
  .ant-menu-submenu-title {
  color: #000 !important;
  opacity: 1;
  display: block !important;
}

.apphide .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  display: none;
}
.apphide .mt-sm-2 .btn-group {
  display: none;
}

.appactionhide
  .css-gl260s-MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable
  .MuiDataGrid-columnHeader:last-child {
  /* display: none; */
}
.appactionhide
  .css-1b64t4v-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell:last-child {
  /* display: none; */
}
.appactionhideuu
  .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  display: none;
}

/* .lasthide
  .css-1b64t4v-MuiDataGrid-root
  .MuiDataGrid-columnHeader[aria-label="Action"]:last-child {
  display: none;
}
.lasthide
  .css-1b64t4v-MuiDataGrid-root
  .MuiDataGrid-cell--textLeft[data-field="action"]:last-child {
  display: none;
} */

.MuiDataGrid-cell--textLeft[data-field="Action"]:last-child {
  display: none;
}

.lasthide
  .css-1b64t4v-MuiDataGrid-root
  .MuiDataGrid-columnHeader--sortable[data-field="Petty_Cash_Request_Status"]:last-child {
  display: none;
}
.lasthide
  .css-1b64t4v-MuiDataGrid-root
  .MuiDataGrid-cell--textLeft[data-field="Petty_Cash_Request_Status"]:last-child {
  display: none;
}
.addonly .btn-group > :not(.btn-check:first-child) + .btn {
  display: none;
}
.MuiDataGrid-columnHeaders {
  background-color: #080808;
  color: #f4a50d;
}

.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps
  .ant-steps-item-icon {
  width: 50px;
  height: 50px;
  background-color: #f5a60b;
  border-color: #f5a60b;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps.ant-steps-vertical {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps
  .ant-steps-item-icon
  .ant-steps-icon {
  display: none;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 3px;
  height: 180px;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps
  .ant-steps-item-icon {
  position: relative;
  width: 50px;
  height: 50px;
}
.lasthide
  .ant-steps-item.ant-steps-item-process.ant-steps-item-active
  .ant-steps-item-content {
  position: absolute;
  left: 8%;
}
.lasthide
  .ant-steps-item.ant-steps-item-process.ant-steps-item-active
  .ant-steps-item-icon {
  margin-bottom: 46px;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  left: 22px;
  position: absolute;
  top: 8px;
  inset-inline-start: 54%;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  opacity: 1;
  background-color: #ccc;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps.ant-steps-vertical
  > .ant-steps-item {
  width: 585px;
  margin: 0 auto;
  position: relative;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps
  .ant-steps-item-container {
  margin-left: 50%;
}
.lasthide
  :where(.css-dev-only-do-not-override-1hyej8k).ant-steps.ant-steps-vertical
  > .ant-steps-item
  .ant-steps-item-content {
  padding: 20px;
  width: 218px;
  border-radius: 7px;
  /* padding: 20px; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  display: flex;
  flex-direction: 95px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
}

.css-1b64t4v-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  max-width: unset !important;
}

.btnverify {
  border: 1px solid;
  width: fit-content;
  padding: 0.4rem;
}
