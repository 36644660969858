/* YourComponent.css */
.blackinputbox {
  background-color: black;
  color: #f5a60b;
  font-weight: bold;
  border: none;
  box-shadow: none;
  text-align: center;
}

.content-container {
  position: relative;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(214, 211, 31, 0); /* Transparent background color */
  z-index: 10; /* Display loader on top of the actual content */
  backdrop-filter: blur(4px); /* Apply a blur effect to the background */
}


.actual-content {
  position: relative;
  z-index: 1; /* Display actual content behind the loader */
}

.blurred {
  filter: blur(4px); /* Apply a blur effect to the actual content when blurred class is present */
}