.form-box {
  padding: 10px;
  width: 80%;
}
.hd-login {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
.input-field-login {
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  margin-top: 10px;
}

@media (max-width: 575.98px) {
  .form-box {
    padding: 10px;
  }
  .hd-login {
    font-size: 23px;
  }
  .input-field-login {
    width: 100%;
    height: 40px;
    padding: 10px 10px;
  }
  .rmd-box {
    font-size: 14px;
  }
}

.ant-modal-ant-modal {
  width: 80% !important;
}
